import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ProjectTwo = ({ show, handleClose }) => {
  
return (
    <>
      <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul className='custom-list'>
  <li> Developed a job board website aimed at enhancing employment opportunities for individuals with disabilities.</li>
  <li>It endeavoured to increase user engagement among individuals with disabilities by offering features tailored to their
requirements. This included addressing communication challenges that users with autism might face.</li>
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectTwo;