import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ShowTwo = ({ show, handleClose }) => {
  
return (
    <>
      <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul className='custom-list'>
<li> Designed and implemented ASP.NET backend APIs for a World Bank Group search application, utilizing C# and .NET
Framework to enhance performance and scalability.</li>
<li> Integrated REST API calls for external data interactions, enabling communication over HTTP using JSON formats.</li>
<li>Collaborated with the business team to collect development requirements, authored test cases in NUnit, and successfully
raised the overall test coverage to 95%.</li>
<li> Implemented a CI/CD pipeline using Jenkins, automating the build, testing, and deployment processes, significantly
reducing manual effort and ensuring continuous integration and delivery.</li>
<li> Configured automated testing within the CI/CD pipeline to run NUnit test cases and POSTMAN tests, ensuring code
quality and reliability before deployment.</li>
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowTwo;