import React from 'react';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import logo from './Images/Image.png';
 import './ContactInfo.css';
import { Button, Row, Col } from 'react-bootstrap';

function Main() {

  const handleDownload = () => {
    const resumeFilePath = '/Resume-Rintu.pdf';
    const link = document.createElement('a');
    link.href = resumeFilePath;
    link.download = 'Resume.pdf'; 
    link.click();
  };

  const handleContactClick = () => {
     window.location.href = 'mailto:rintunoel27@gmail.com';
  }


  return (
   <>

<div class="container">
      <Figure>
          <Figure.Image
              width={230}
              height={230}
              alt="Logo"
              src={logo} 
              roundedCircle
              className="image-right-corner"
          />
      </Figure>

    <div class="col-md-6" className="AboutMe">
    <h6>Hello,I'm</h6>
    <h1>Rintu Noelmon</h1>
    <h4>Software Developer</h4>
      <div>
        <Button  variant="dark"  className="AboutMe-button" onClick={handleDownload} ><i class='bx bx-cloud-download'></i>Download CV</Button> 
        <button type="button" onClick={handleContactClick} class="btn btn-light" data-bs-toggle="modal" data-bs-target="#contactModal">Contact Info</button>
      </div><br></br>
      <div className='AboutMe-icons'>
        <i class='bx bxl-instagram' href="https://www.instagram.com/alexandria_rintu?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" ></i>
        <i class='bx bxl-github' href="https://github.com/RintuNoelmon"></i>
        <i class='bx bxl-linkedin-square' href="https://www.linkedin.com/in/rintu-noelmon/" ></i>
      </div>
    </div>

</div>


      
      







   {/* <Container>
    <Row className='Rintu' >

    <Col>
      <Row className='header-icons'>
  
        <i class='bx bxl-instagram' href="https://www.instagram.com/alexandria_rintu?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" ></i>
        <i class='bx bxl-github' href="https://github.com/RintuNoelmon"></i>
        <i class='bx bxl-linkedin-square' href="https://www.linkedin.com/in/rintu-noelmon/" ></i>
                
      </Row>
    </Col>

    <Col>
     <div className="Auth-form-container">
          <form className="Auth-form">
            <div className="Auth-form-content">
              <h4 className="Auth-form-title">Rintu Noelmon</h4>
                <div className="text-center">
                   Quick learner with a passion for problem-solving and a proven ability to adapt to new technologies. 
                   Eager to contribute to a dynamic team and gain practical experience in a challenging role.
                </div><br></br>

              <h4 className="text-center"><strong>Technologies</strong></h4>
                <div className="text-center">
              
                  <dl>
                  <dt>Programming Languages</dt>
                  <dd>- C++, Python, Java, C# | .NET Framework </dd>
                  <dt>Web Technologies</dt>
                  <dd>- HTML, CSS, JavaScript - ReactJS, NodeJS, Jquery, TypeScript</dd>
                  <dt>Database</dt>
                  <dd>- MySQL, MongoDB</dd>
                  <dt>Software Management Tools</dt>
                  <dd>- SmartSheets, Zoho Projects.</dd>
               </dl>     
            
              </div>
            </div>
          </form>
        </div>
     </Col>

     <Col>
     <Row>
        <Col className="text-center">
          <Figure>
            <Figure.Image
              width={230}
              height={230}
              alt="Logo"
              src={logo} 
              roundedCircle
              className="right-corner"
            />
          </Figure>
          <Button variant="dark"  className="text-corner" onClick={handleDownload} ><i class='bx bx-cloud-download'></i>Download CV</Button> 
        
        </Col>
  
      </Row>
     
     </Col>

    </Row>
   </Container> */}


   
    </>
  );
}

export default Main;
