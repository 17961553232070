import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ProjectThree = ({ show, handleClose }) => {
  
return (
    <>
      <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul className='custom-list'>
  <li> Implemented a Jeopardy game utilizing Python, featuring a backend system that generated an extensive pool of 10,000
questions, ensuring a rich and varied gaming experience.</li>
  <li> Designed the game to be seamlessly shared and played across various virtual communication platforms, such as Zoom
and Microsoft Teams.</li>
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectThree;