import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ShowThree = ({ show, handleClose }) => {
  
return (
    <>
      <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul className='custom-list'>
<li> Crafted a client-side web part using SharePoint Framework (SPFx) alongside modern web technologies including
TypeScript, HTML, and CSS. Deployed the web part to SharePoint Online and integrated it within SharePoint Pages.</li>
<li> Integrated Azure services within the web part, leveraging Azure Blob Storage for binary data storage.</li>
<li> Utilized Azure Functions for backend processes, used HTTP requests to trigger Azure endpoints, improving data retrieval
speed and overall web part efficiency.</li>

        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowThree;