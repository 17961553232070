import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ProjectOne = ({ show, handleClose }) => {
  
return (
    <>
      <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
          <Modal.Title>Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul className='custom-list'>
  <li> <strong>Issue Addressed:</strong> WebCraftifyAI - overlapblock Creator - Web design - Contributed multiple web designs to this
project. Utilized HTML, CSS, and JavaScript to create a dynamic and responsive block that fits seamlessly within the
Website’s Design</li>
  <li> <strong>Issue Addressed:</strong> Hooman - Add more hooman demos! -Developed an engaging arcade-style game, ”Wall Breaker,”
using Pygame and the Hooman library to demonstrate its capabilities.</li>
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectOne;