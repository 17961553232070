import { Project_Data } from "./Project_Data";
import './Project.css';
import logo1 from './Images/project1.png';
import logo2 from './Images/project2.jpg';
import Figure from 'react-bootstrap/Figure';
import ProjectOne from './ProjectOne';
import ProjectTwo from './ProjectTwo';
import ProjectThree from './ProjectThree';

import { useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Parallax, Pagination, Navigation } from 'swiper/modules';

function Project() {

const[open, setShow] = useState(false);
const[opentwo, setShowtwo] = useState(false);
const[openthree, setShowthree] = useState(false);

const handleShow = () => {
  return setShow(true);
}
const handleClose = () => setShow(false);

const handleShowTwo = () => setShowtwo(true);
const handleCloseTwo = () => setShowtwo(false);

const handleShowThree = () => setShowthree(true);
const handleCloseThree = () => setShowthree(false);
  
return (
    <>

<div class="container">
        <div class="row" id="Pro1">
            <div class="col-md-3" id="Pro"> 
            <Figure><Figure.Image
              width={250}
              height={250}
              alt="Logo"
              src={logo1} />
            </Figure>
               
                <h4>Open Source Contribution</h4>
                <button type="button" onClick={handleShow} class="btn btn-dark">Open</button>
            </div>
            <div class="col-md-3" id="Pro">
            <Figure><Figure.Image
              width={250}
              height={250}
              alt="Logo"
              src={logo2} />
            </Figure>

                <h4>Empowering Differently Abled people</h4>
                <button type="button" onClick={handleShowTwo} class="btn btn-dark">Open</button>
            </div>
            <div class="col-md-3" id="Pro">
            <Figure><Figure.Image
              width={250}
              height={250}
              alt="Logo"
              src={logo1} />
            </Figure>

                <h4>Jeopardy</h4>
                <button type="button" onClick={handleShowThree} class="btn btn-dark">Open</button>
            </div>

        </div>
    </div>


     {/* <section>
       <Swiper 
         style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Parallax, Pagination, Navigation]}
       > 

       <div
          slot="container-start"
          className="parallax-bg"
          style={{
            'background-image':
              'url(https://img.freepik.com/premium-photo/dark-black-background-with-notebook-pen-premium-photo_659511-11.jpg)',
              
          }}
          data-swiper-parallax="-23%"
        ></div>

         {
            Project_Data.map(({id,title,description})=>{
                return(
                    <SwiperSlide className="project_card" key={id}>

                        <h3 className="project_name">{title}</h3>
                        <p className="project_description">{description}</p>

                    </SwiperSlide>
                )
            })

        }
         


       </Swiper>
     </section>
     */}

     <ProjectOne show={open} handleClose={handleClose}/>    
     <ProjectTwo show={opentwo} handleClose={handleCloseTwo}/>    
     <ProjectThree show={openthree} handleClose={handleCloseThree}/>    


    </>

  );
}
export default Project;